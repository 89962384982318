import axios from "axios";

const API_ENDPOINT = {
  local: `http://localhost:4022/v1`,
  dev: `https://stage.panelapi.bytesbrick.com/v1`,
  stage: `https://stage.panelapi.gotosample.com/v1`,
  prod: `https://panelapi.gotosample.com/v1`,
};

const defaultEnv = "local";
const envFromVars = (process.env.ENVIRONMENT as keyof typeof API_ENDPOINT) || defaultEnv;

const env = envFromVars ? envFromVars : defaultEnv;

const BASE_URL = API_ENDPOINT[env];

export const enum TARGET_API {
  BASE = "BASE",
}

const trimSlash = (url: string) => {
  return url.replace(/^\/|\/$/g, "");
};

export const urlJoin = (baseUrl: string, url: string) => {
  if (/^https?:/gi.test(url)) {
    return url;
  }
  if (/^https?:/gi.test(baseUrl)) {
    return `${trimSlash(baseUrl)}/${trimSlash(url)}`;
  }
  return `${trimSlash(baseUrl)}/${trimSlash(url)}`;
};

export const buildURL = (url: string, targetAPI: TARGET_API) => {
  let apiHost;
  switch (targetAPI) {
    case TARGET_API.BASE:
      apiHost = BASE_URL;
      break;

    default:
      apiHost = BASE_URL;
      break;
  }
  return urlJoin(apiHost, url);
};

const fetchIpFromGeolocationDb = async () => {
  try {
    const response = await axios.get('https://geolocation-db.com/json/');
    return response.data.IPv4;
  } catch (error) {
    console.log('geolocation-db failed');
    return null;
  }
};

const fetchIpFromIpify = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.log('ipify failed', error);
    return null;
  }
};

const fetchIpFromIpApi = async () => {
  try {
    const response = await axios.get('http://ip-api.com/json');
    return response.data.query;
  } catch (error) {
    console.log('ip-api failed', error);
    return null;
  }
};

const fetchIpFromIpinfo = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json');
    return response.data.ip;
  } catch (error) {
    console.log('ipinfo.io failed', error);
    return null;
  }
};

export const fetchIpAddress = async () => {
  try {
    return await fetchIpFromIpify();
  } catch {
    try {
      return await fetchIpFromGeolocationDb();
    } catch {
      try {
        return await fetchIpFromIpApi();
      } catch {
        try {
          return await fetchIpFromIpinfo();
        } catch (error) {
          console.log('All IP fetch attempts failed', error);
          return null;
        }
      }
    }
  }
};