import axios from "axios";
const API_BASE = "https://gtsapi.bytesbrick.com/v1" // dev

export const postAPI = async function (url, data) {
//   let _token = localStorage.getItem("token");
//   if(!_token){
//     _token = data._token
//   }
  const headers = {
    // "Content-Type": "application/json",
    // Authorization: "Bearer " + _token,
  };
  let responseMessage;
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
    //   headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    responseMessage = postApiRes.data;
    if(responseMessage.status ===0){
      if(responseMessage.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return postApiRes.data;
  } catch (err) {
    return err.response.data
  }
};

export const get = async function (url, data, queryParameter) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "GET",
      url: url,
      data: JSON.stringify(data),
      params : queryParameter,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    
    if(getApiRes.data.status ===0){
      if(getApiRes.data.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return getApiRes.data;
  } catch (err) {
    return err.response.data
  }
};

export const putAPI = async function (url, data) {
  let _token = window.localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "PUT",
      url: url,
      data: data,
      headers: headers,
    };
    const putApiRes = await axios(requestOptions);
    if(putApiRes.data.status ===0){
      if(putApiRes.data.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return putApiRes.data;
  } catch (err) {
    return err.response.data
  }
};

export const deleteAPI = async function (url, data) {
  let _token = window.localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = API_BASE + url;
    let requestOptions = {
      method: "DELETE",
      url: url,
      data: JSON.stringify(data),
      // params : queryParameter,
      headers: headers,
    };
    const deleteApiRes = await axios(requestOptions);
    if(deleteApiRes.data.status ===0){
      if(deleteApiRes.data.messageCode === 'TOKEN_EXPIRED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return deleteApiRes.data;
  } catch (err) {
    return err.response.data
  }
};

export const uploadImageAPI = async function (url, fileData) {
  let _token = localStorage.getItem("token");
  var formData = new FormData();
  // console.log('formdata', fileData)
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  // formData.append("files", fileData[0]);
  // console.log('form data in api ervice', formData);
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer  " + _token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: API_BASE + url,
      data: formData,
      headers: headers,
    });
    // checkToken(postApiRes.data)
    return postApiRes.data;
  } catch (err) {
    return err.response.data
  }
};